<template>
  <div
    :class="['editAddressBox', isSelection ? 'selectionEditAddressBox' : '']"
  >
    <div class="editAddress">
      <!-- 用户姓名 -->
      <div class="userBox">
        <section class="itemBox newItemBox">
          <p class="itemBoxLeft">{{ $t.address.consigneeName }}</p>
          <input
            class="itemBoxRight"
            v-model="editAddress.ReceivingName"
            :placeholder="$t.address.pleaseEnter + $t.address.name"
          />
        </section>
        <!-- <section class="itemBox">
          <p class="itemBoxLeft">收货人名</p>
          <input class="itemBoxRight" v-model="editAddress.ReceivingName"/>
        </section>-->
      </div>
      <section class="itemBox2">
        <p class="itemBox2Left">{{ $t.address.phone }}</p>
        <input
          class="itemBox2Right"
          v-model="editAddress.PhoneNumber"
          :placeholder="$t.address.pleaseEnter + $t.address.phone"
        />
      </section>
      <section class="itemBox2">
        <p class="itemBox2Left">{{ $t.address.country }}</p>
        <div class="itemBox2Right">
          <div class="countryBox" @click="onShowCountry">
            <span v-if="editAddress && editAddress.CountryCode">{{
              editAddress.CountryStr
            }}</span>
            <span v-else class="unselect">{{ $t.address.select }}</span>
          </div>
        </div>
      </section>
      <section class="itemBox2">
        <p class="itemBox2Left">{{ $t.address.city }}</p>
        <!-- <p class="itemBox2Right">54545</p> -->
        <input
          class="itemBox2Right"
          v-model="editAddress.City"
          :placeholder="$t.address.pleaseEnter + $t.address.city"
        />
      </section>
      <section class="itemBox2">
        <p class="itemBox2Left">{{ $t.address.postcode }}</p>
        <!-- <p class="itemBox2Right">54545</p> -->
        <input
          class="itemBox2Right"
          v-model="editAddress.PostalCode"
          :placeholder="$t.address.pleaseEnter + $t.address.postcode"
        />
      </section>
      <!-- 详细地址 -->
      <div class="userBox">
        <section class="itemBox newItemBox">
          <p>{{ $t.address.address }}</p>
        </section>
        <section class="itemBox">
          <textarea
            class="address"
            v-model="editAddress.ReceivingAddress"
            rows="5"
            :placeholder="$t.address.pleaseEnter + $t.address.address"
          ></textarea>
        </section>
      </div>
      <!-- 设置为默认地址 -->
      <section class="itemBox2">
        <p class="itemBox2Left">{{ $t.address.setDefaultAddress }}</p>
        <p class="itemBox2Right">
          <van-switch
            v-model="editAddress.IsDefult"
            active-color="#07c160"
            size="22px"
            inactive-color="#666666"
          />
        </p>
      </section>
    </div>
    <div class="addressBox">
      <div
        class="deleteBt"
        @click="deleteAddress"
        v-if="isEdit && !isSelection"
      >
        {{ $t.editAddress.delete }}
      </div>
      <div class="addressBt" @click="sub">{{ $t.editAddress.done }}</div>
    </div>

    <country-list
      :nationList="nationList"
      @onSelectCountry="onSelectCountry"
    ></country-list>
  </div>
</template>
<script>
import Vue from "vue";
import { DropdownMenu, DropdownItem, Switch, Search } from "vant";
import Schema from "async-validator";
import CountryList from "@components/countryList/CountryList.vue";

Vue.use(DropdownMenu).use(DropdownItem).use(Switch).use(Search);

export default {
  name: "EditAddress",
  props: {
    id: {
      type: String,
    },
    isSelection: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CountryList,
  },
  data() {
    return {
      nationList: [], // 国家列表
      // aa: true,
      editAddress: {
        URId: -1,
        ReceivingName: "",
        ReceivingAddress: "",
        PhoneNumber: "",
        CountryCode: "",
        City: "",
        IsDefult: false,
        PostalCode: "",
      },
    };
  },
  computed: {
    isEdit() {
      return this.id !== undefined && this.id !== null;
    },
  },
  created() {
    if (this.isEdit) {
      this.request();
    }
    this.getCountrys();
  },
  methods: {
    onShowCountry() {
      this.$bus.$emit("showCountryList", true);
    },
    onSelectCountry(item) {
      this.editAddress.CountryStr = item.text;
      this.editAddress.CountryCode = item.value;
    },
    request() {
      this.$api.users
        .loadUsersReceivingInfo({
          URId: this.id,
        })
        .then((res) => {
          const data = res.data;
          data.IsDefult = data.IsDefult > 0;
          this.editAddress = res.data;
        })
        .catch((err) => {
          this.$commonMethod.showToast({
            type: "error",
            titleMsg: err.message,
          });
        });
    },
    // 获取国家列表
    getCountrys() {
      this.$commonMethod.showLoading();
      let param = {};
      this.$api.system
        .getCountrys(param)
        .then((res) => {
          // console.log(res)
          // this.nationList = res.data
          this.formatNation(res.data);
          this.$commonMethod.hideLoading();
        })
        .catch((error) => {
          this.isfirst = false;
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 格式化国家选择列表
    formatNation(nations) {
      // console.log(nations)
      nations.forEach((item) => {
        let nation = {
          text: item.CountryEnglishName,
          value: item.CountryA2,
        };
        this.nationList.push(nation);
      });
      // console.log(this.nationList)
    },
    deleteAddress() {
      this.$commonMethod.showLoading();
      let param = {
        URId: this.editAddress.URId,
      };
      this.$api.user
        .deleteUsersReceiving(param)
        .then((res) => {
          this.$commonMethod.hideLoading();
          if (this.isSelection) {
            this.$emit("addressDidChanged");
          } else {
            this.$commonMethod.dialogAlert(
              res.message ? res.message : res.Message,
              () => {
                this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
              }
            );
          }
        })
        .catch((error) => {
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
    // 保存操作
    sub() {
      const rule = {
        ReceivingName: {
          required: true,
          message: this.$t.address.pleaseEnter + this.$t.address.name,
        },
        ReceivingAddress: {
          required: true,
          message: this.$t.address.pleaseEnter + this.$t.address.address,
        },
        PhoneNumber: {
          required: true,
          message: this.$t.address.pleaseEnter + this.$t.address.phone,
        },
        PostalCode: {
          required: true,
          message: this.$t.address.pleaseEnter + this.$t.address.postcode,
        },
        CountryCode: {
          required: true,
          message: this.$t.address.select + " " + this.$t.address.country,
        },
        City: {
          required: true,
          message: this.$t.address.select + " " + this.$t.address.city,
        },
      };
      const validator = new Schema(rule);
      validator
        .validate(this.editAddress)
        .then((_) => {
          this.$commonMethod.showLoading();
          let param = {
            URId: this.editAddress.URId,
            ReceivingName: this.editAddress.ReceivingName,
            ReceivingAddress: this.editAddress.ReceivingAddress,
            PhoneNumber: this.editAddress.PhoneNumber,
            CountryCode: this.editAddress.CountryCode,
            City: this.editAddress.City,
            IsDefult: this.editAddress.IsDefult ? 1 : 0,
            PostalCode: this.editAddress.PostalCode,
          };
          !this.isEdit && delete param.URId;
          return this.$api.user.addUsersReceiving(param);
        })
        .then((res) => {
          console.log(res);
          // this.nationList = res.data
          this.$commonMethod.hideLoading();
          if (this.isSelection) {
            this.$emit("addressDidChanged");
          } else {
            this.$commonMethod.dialogAlert(
              res.message ? res.message : res.Message,
              () => {
                this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
              }
            );
          }
        })
        .catch((err) => {
          if (err.errors) {
            this.$commonMethod.showNotify({
              type: "danger",
              titleMsg: err.errors[0].message,
            });
          } else {
            this.$commonMethod.hideLoading();
            this.$commonMethod.showToast({
              type: "error",
              titleMsg: err.message,
            });
          }
        });
    },
    // 保存与新增
    addUsersReceiving() {
      this.$commonMethod.showLoading();
      let param = {
        URId: this.editAddress.uRId,
        ReceivingName: this.editAddress.receivingName,
        ReceivingAddress: this.editAddress.ReceivingAddress,
        PhoneNumber: this.editAddress.PhoneNumber,
        CountryCode: this.editAddress.CountryCode,
        City: this.editAddress.City,
        IsDefult: this.editAddress.IsDefult ? 1 : 0,
        PostalCode: this.editAddress.PostalCode,
      };
      !this.isEdit && delete param.URId;
      this.$api.user
        .addUsersReceiving(param)
        .then((res) => {
          console.log(res);
          // this.nationList = res.data
          this.$commonMethod.hideLoading();
          if (this.isSelection) {
            this.$emit("addressDidChanged");
          } else {
            this.$commonMethod.dialogAlert(
              res.message ? res.message : res.Message,
              () => {
                this.$commonEnv.commonAction(this.$commonEnv.actionType.pop);
              }
            );
          }
        })
        .catch((error) => {
          this.isfirst = false;
          // console.log(error);
          this.$commonMethod.hideLoading();
          this.$commonEnv.commonAction(this.$commonEnv.actionType.showDialog, {
            msg: error.message,
            status: error.status,
          });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
@mixin newBox1 {
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 10px;
}
@mixin newBox2 {
  background-color: #ffffff;
  margin-top: 10px;
  padding: 18px 15px;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  border-radius: 8px;
  @include publicFlex;
}
@mixin boxLeft {
  max-width: 35%;
  text-align: left;
  color: #000000;
}
@mixin boxright {
  max-width: 65%;
  text-align: right;
  font-size: 14px;
  color: #000000;
  font-weight: bold;
  background: none;
  outline: none;
  border: none;
}
.selectionEditAddressBox {
  .editAddress {
    margin-bottom: 25vh !important;
  }
}
.editAddressBox {
  width: 100%;
  padding-bottom: 75px;
  .editAddress {
    width: 92%;
    font-size: 14px;
    margin: 0 auto;
    ::v-deep .van-popup--top {
      top: 0;
      left: auto;
      /* width: 100%; */
      right: 4%;
      width: 40%;
    }
    ::v-deep .van-overlay {
      position: fixed !important;
    }
    * {
      //font-size: 14px;
      color: #333333;
      margin: 0;
      padding: 0;
    }
    .userBox {
      @include newBox1;
      .itemBox {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        padding: 18px 15px;
        @include publicFlex;
        .itemBoxLeft {
          @include boxLeft;
        }
        .itemBoxRight {
          @include boxright;
        }
        .address {
          width: 100%;
          border: none;
        }
      }
      .newItemBox {
        border-bottom: 1px solid #f6f6f6;
      }
    }
    .itemBox2 {
      @include newBox2;
      .itemBox2Left {
        @include boxLeft;
      }
      .itemBox2Right {
        @include boxright;
        .countryBox {
          width: 40vw;
          .unselect {
            color: #777777;
          }
        }
      }
    }
  }
  .addressBox {
    width: 100%;
    position: fixed;
    background-color: #f6f6f6;
    bottom: 0;
    z-index: 2;
    padding-top: 15px;
    padding-bottom: calc(15px + env(safe-area-inset-bottom));
    border-top: 1px solid #e5e5e5;
    .addressBt {
      position: relative;
      margin: 0 auto;
      border-radius: 25px;
      border: 1px solid #48d58e;
      background: linear-gradient(left, #71d283, #01aaa3);
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 40px;
      width: 92%;
    }
    .deleteBt {
      position: relative;
      margin: 0 auto;
      margin-bottom: 0.5rem;
      border-radius: 25px;
      border: 1px solid #ff5852;
      background: #ff5852;
      text-align: center;
      color: #ffffff;
      font-size: 15px;
      line-height: 40px;
      width: 92%;
    }
  }
}
</style>
