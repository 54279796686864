<template>
  <van-popup v-model="onShow" position="bottom" class="pay-popup">
    <van-row align="center" class="pay-header">
      <van-search
        v-model="value"
        @input="onSearch"
        @clear="onReset"
        onkeyup="value=value.replace(/[^a-z]/g,'')"
        shape="round"
        :placeholder="$t.meta.search"
      />
    </van-row>

    <div class="payBottom" v-if="items">
      <van-cell-group
        v-for="(item, index) in items"
        :key="index"
        @click="onClick(item)"
      >
        <van-cell :title="item.text" />
      </van-cell-group>
    </div>
  </van-popup>
</template>

<script>
import Vue from "vue";
import { Popup, Row, CellGroup, Cell, Search } from "vant";
Vue.use(Popup).use(Row).use(CellGroup).use(Cell).use(Search);

export default {
  name: "CountryList",
  props: ["nationList"],
  data() {
    return {
      value: "",
      items: [],
      onShow: false,
    };
  },
  created() {
    this.items = this.nationList;
    this.$bus.$off("showCountryList").$on("showCountryList", (isShow) => {
      this.onShow = isShow;
    });
  },
  methods: {
    onSearch() {
      var items = [];
      for (const item of this.items) {
        var text = item.text.toLowerCase();
        if (text.includes(this.value)) {
          items.push(item);
        }
      }
      if (items && items.length > 0) {
        if (this.value) {
          this.items = items;
        } else {
          this.items = this.nationList;
        }
      }
    },
    onReset() {
      this.items = this.nationList;
    },
    onClick(item) {
      this.$emit("onSelectCountry", item);
      this.onShow = false;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~assets/scss/publicStyle.scss";
.pay-popup {
  height: 70%;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  .pay-header {
    width: auto;
    padding: 2%;
    .pay-title {
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
    .pay-subtitle {
      width: 100%;
      text-align: center;
      font-weight: bold;
      font-size: 2rem;
      margin-top: 0.5rem;
      .boxUnit {
        font-size: 1.5rem;
        margin-left: 4px;
        margin-right: 4px;
      }
    }
  }
}
</style>